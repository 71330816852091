import http from "../http-common";

const getAll = async (param) => {
  const res = await http.get(`/advance-payment`, { params: param });
  return res.data;
};

const get = async (id) => {
  const res = await http.get(`/advance-payment/${id}`);
  return res.data;
};

const create = async (data) => {
  return http.post("/advance-payment", data);
};

const remove = async (id, advanceId) => {
  const res = await http.delete(`/advance-payment/${id}`, {
    params: { advanceId: advanceId },
  });
  return res.data;
};

const AdvanceService = {
  getAll,
  get,
  create,
  remove,
};

export default AdvanceService;
