import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import Moment from "react-moment";
import RequisitionService from "../../services/RequisitionService";
import QuotationService from "services/QuotationService";
const CreateQuotation = () => {
  let { requisitionId } = useParams();
  const navigate = useNavigate();
  const [requisition, setRequisition] = useState({});
  const [list, setList] = useState([]); /* for adding part in quotation */
  const [machineId, setMachineId] = useState("");
  const [itemData, setItemData] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [vat, setVat] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [data, setData] = useState({
    requisition_id: "",
    type: "",
    company_id: "",
    machine_id: "",
    vat: "",
    discount: "",
    discount_type: "percentage",
    vat_type: "percentage",
    grand_total: grandTotal,
    sub_total: total,
    part_items: list,
  });

  const [block, setBlock] = useState(false);
  const getRequisition = async () => {
    let res = await RequisitionService.get(requisitionId);
    setRequisition(res);
  };

  const handleChange = (e, item) => {
    const { name } = e.target;
    const templist = [...list];
    const tempItem = templist?.filter((val) => val?.id === item?.id);
    const tempItemPart = tempItem[0]?.part;
    tempItemPart[name] = parseInt(e.target.value);
    setList(templist);
  };
  useEffect(() => {
    let totalAmount = 0;
    data?.part_items?.forEach((item) => {
      totalAmount =
        parseInt(totalAmount) +
        parseInt(item?.quantity) * parseInt(item?.unit_value);
    });
    setTotal(totalAmount);

    setDiscount(parseFloat(data.discount_type ==='percentage' ? (total * data?.discount) / 100 : data?.discount));
    setVat(parseFloat(data.vat_type ==='percentage' ? ((total - discount) * data?.vat) / 100 : data?.vat));    
    setGrandTotal((total - discount) + vat);
  }, [data?.part_items,data?.vat,data?.discount,data?.vat_type,data?.discount_type]);

  const handleDataChange = (e) => {
    let value;
    if (e.target.name == "vat" || e.target.name == "discount") {
      value = Number(e.target.value);
    } else {
      value = e.target.value;
    }
    setData({ ...data, [e.target.name]: value });
  };

  const handleNewChange = (e, item) => {
    const tempData = [...itemData];
    const tempItem = tempData?.map((val) => {
      if (val?.id == item?.id) val["unit_value"] = e.target.value;

      return val;
    });

    setItemData(tempItem);

    const tempList = [...list];
    const tempNewItem = tempList?.filter((val) => val.id == item?.id);
    const tempNewItemPart = tempNewItem[0];

    const itemDataPart = itemData?.filter(
      (it) => it?.id == tempNewItemPart?.id
    );

    tempNewItemPart["unit_value"] = itemDataPart[0]["unit_value"];

    setList(tempList);
  };

  const increment = (item) => {
    const tempList = [...list];
    const tempItem = tempList?.filter((val) => val?.id === item?.id);
    ++tempItem[0].quantity;
    setList(tempList);
  };

  const decrement = (item) => {
    const tempList = [...list];
    const tempItem = tempList.filter((val) => val.id === item.id);
    --tempItem[0].quantity;
    setList(tempList);
  };

  // store quotation
  const storeQuotation = async () => {
    setBlock(true);
    let res = await QuotationService.create(data);
    setBlock(false);
    navigate(`/panel/quotations/${res?.data?.id}`);
  };
  // Remove Quotation
  const removeItem = (id) => {
    const newList = list.filter((item) => item.id !== id);
    setList(newList);
  };

  useEffect(() => {
    if (requisitionId) getRequisition();
  }, [requisitionId]);

  useEffect(() => {
    setList(requisition?.part_items); //Add to List into Requisition Part Items
    setItemData(
      requisition?.part_items?.map((dt) => {
        return {
          unit_value:
            dt?.part?.stocks[dt?.part?.stocks.length - 1]?.selling_price, 
          id: dt.id,
        };
      })
    );
    setMachineId(requisition?.machines?.map((item) => item.id));
    setData({
      ...data,
      vat: 0,
      discount: 0,
      requisition_id: requisition?.id,
      type: requisition?.type,
      company_id: requisition?.company_id,
      machine_id: requisition?.machines?.map(
        (item, index) => item?.machine_model_id
      ),
    }); //need to fix this
  }, [requisitionId, requisition]);

  useEffect(() => {
    setData({ ...data, part_items: list,grand_total: grandTotal,sub_total: total }); //add part_items and total amount in data
  }, [list,grandTotal,total]);

  return (
    <div className="post d-flex flex-column-fluid" id="content">
      <div className="container-xxl">
        <div className="card">
          <div className="card-body py-20">
            <div className="mw-lg-950px mx-auto w-100">
              <div className="mb-19">
                <div className="row">
                  <div className="col-sm-3">
                    <Link to="#">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/naf3.jpeg"
                        style={{ width: "5rem" }}
                      />
                    </Link>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="text-sm-center fw-bold fs-4 text-muted "
                      style={{ textAlign: "center", marginLeft: "2rem" }}
                    >
                      <h1>Naf Overseas(PVT.) Ltd.</h1>
                      <p className="text-sm">
                        <small>Head Office:Naya paltan,Dhaka,Bangladesh</small>
                        <br />
                        <small>
                          Tel:44564,Fax:1234,Email:example@gmail.com,Web:example.com
                        </small>
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-3 text-sm-end">
                    <Link to="#">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/tajima.png"
                        style={{ width: "5rem", marginLeft: "2rem" }}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-sm-12 mt-5">
                  <div className="text-sm-center">
                    <h1 className="text-uppercase">Quotation</h1>
                  </div>
                </div>
              </div>

              <div className="">
                <table width="100%">
                  <tr>
                    <td>
                      <h6>
                        <strong>Company Name:</strong>
                        <span className="text-muted">
                          {requisition?.company?.name}
                        </span>
                      </h6>

                      <h6>
                        <strong>Group of Company: </strong>
                        <span className="text-muted">
                          {requisition?.company?.company_group}
                        </span>
                      </h6>
                    </td>
                    <td style={{ marginLeft: "120px" }} width="30%"></td>

                    <td style={{ marginLeft: "120px" }}>
                      <h6>
                        <strong>Date: </strong>
                        <span className="text-muted">
                          <Moment format="D MMMM YYYY">
                            {requisition?.created_at}
                          </Moment>
                        </span>
                      </h6>
                      <h6>
                        <strong>Engineer Name: </strong>
                        <span className="text-muted">
                          {requisition?.engineer?.name}
                        </span>
                      </h6>
                      <h6>
                        <strong>Email: </strong>
                        <span className="text-muted">
                          {requisition?.engineer?.email}
                        </span>
                      </h6>
                    </td>
                  </tr>
                </table>

                <div className="mt-5">
                  <h6>
                    <strong>Machine Model: </strong>
                    <span className="text-muted">
                      {requisition?.machines?.map((item, index) => (
                        <span key={index}>{item?.model?.name}</span>
                      ))}
                    </span>
                  </h6>
                </div>

                <div className="d-flex justify-content-between flex-column flex-md-row">
                  <div className="flex-grow-1 pt-8 mb-13">
                    <div className="table-responsive ">
                      <table className="table align-middle">
                        <thead>
                          <tr className="fs-6 fw-bolder text-dark text-uppercase">
                            <th className="min-w-75px pb-9">SL.No</th>
                            <th className="min-w-70px pb-9 text-end">
                              Parts Name
                            </th>
                            <th className="min-w-80px pb-9 text-end">
                              Parts Number
                            </th>
                            <th className="min-w-80px pb-9 text-end">
                              Yen Price
                            </th>
                            <th className="min-w-80px pb-9 text-end">
                              Formula Price
                            </th>
                            <th className="min-w-150px pb-9 text-end">
                              Selling Price
                            </th>
                            <th className="min-w-100px pe-lg-6 pb-9 text-end">
                              Quantity
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {list?.map((item, index) => (
                            <tr
                              className="fw-bolder text-gray-700 fs-5 text-end"
                              key={index}
                            >
                              <td className="d-flex align-items-center pb-10">
                                {index + 1}
                              </td>
                              <td>{item?.part?.aliases[0]?.name}</td>
                              <td>{item?.part?.aliases[0]?.part_number}</td>
                              <td>
                                <input
                                  disabled
                                  type="number"
                                  className="form-control"
                                  aria-label="Small"
                                  aria-describedby="inputGroup-sizing-sm"
                                  name="yen_price"
                                  placeholder="0TK"
                                  value={
                                    item?.part?.stocks[
                                      item?.part?.stocks.length - 1
                                    ]?.yen_price ?? ""
                                  }
                                  onChange={(e) => handleChange(e, item)}
                                />
                              </td>
                              <td>
                                <input
                                  disabled
                                  type="number"
                                  className="form-control"
                                  name="formula_price"
                                  placeholder="0TK"
                                  value={
                                    item?.part?.stocks[
                                      item?.part?.stocks.length - 1
                                    ]?.formula_price ?? ""
                                  }
                                  onChange={(e) => handleChange(e, item)}
                                />
                              </td>

                              <td>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="selling_price"
                                  placeholder="0TK"
                                  value={
                                    itemData?.find((x) => x.id === item.id)
                                      ?.unit_value ?? ""
                                  }
                                  onChange={(e) => handleNewChange(e, item)}
                                />
                              </td>

                              <td className="product-quantity">
                                <div className="input-group">
                                    <span
                                      className="input-group-text"
                                      onClick={() => {
                                        if (item?.quantity > 0) {
                                          decrement(item);
                                        }
                                      }}
                                    >
                                      <i className="fas fa-minus"></i>
                                    </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    min="1"
                                    value={item?.quantity ?? ""}
                                    // defaultValue={item?.quantity ?? ""}
                                    name="quantity"
                                  />
                                    <span
                                      className="input-group-text"
                                      onClick={() => increment(item)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <i className="fas fa-plus"></i>
                                    </span>
                                </div>
                              </td>
                              <td className="text-end">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-icon btn-danger"
                                  data-kt-element="remove-item"
                                  onClick={() => removeItem(item?.id)}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                          {data?.type == "purchase_request" && (
                            <>
                              <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                <td colSpan={4}></td>
                                <td>Sub-total</td>
                                <td></td>
                                <td>{total}</td>
                                <td></td>
                              </tr>
                              <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                <td colSpan={4}></td>
                                <td>Discount</td>
                                <td>
                                <div className="input-group">
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="discount"
                                      value={data?.discount}
                                      onChange={handleDataChange}
                                    />
                                    <select className="form-select" name="discount_type" onChange={handleDataChange}>
                                      <option value="percentage">%</option>
                                      <option value="fixed">BDT</option>
                                    </select>
                                  </div>
                                </td>
                                <td>- { parseFloat(data.discount_type ==='percentage' ? (total * data?.discount) / 100 : data?.discount) }</td>
                                <td></td>
                              </tr>
                              <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                <td colSpan={4}></td>
                                <td className="align-center justify-content-center">
                                  Vat
                                </td>
                                <td className="">
                                <div className="input-group">
                                    <input
                                      type="number"
                                      className="form-control"
                                      name="vat"
                                      value={data?.vat}
                                      onChange={handleDataChange}
                                    />
                                    <select className="form-select" name="vat_type" onChange={handleDataChange}>
                                      <option value="percentage">%</option>
                                      <option value="fixed">BDT</option>
                                    </select>
                                  </div>
                                </td>
                                <td>+ { parseFloat(data.vat_type ==='percentage' ? (grandTotal * data?.vat) / 100 : data?.vat) }</td>
                                <td></td>
                              </tr>
                              <tr className="fw-bolder text-gray-700 fs-5 text-end">
                                <td colSpan={4}></td>
                                <td>Grand Total</td>
                                <td></td>
                                <td>
                                  {grandTotal}
                                </td>
                                <td></td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                      <div className="separator separator-dashed"></div>
                      <button
                        className="btn btn-primary mt-5"
                        onClick={() => {
                          storeQuotation();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateQuotation;
